import React, { useEffect, useState } from "react"
import { withFormik, Form } from "formik"
import * as Yup from "yup"
import { graphql, useStaticQuery } from "gatsby"
import Heading from "../../components/content/heading"
import { Footer, Rows, Columns } from "../../components/grid"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import Link from "../../components/content/link"
import Copy from "../../components/content/copy"
import FormField from "./fields/form-field"

function RegisterForm({
  name,
  agency,
  errors,
  touched,
  status,
  setFieldValue,
}) {
  const { strapiPageRegister } = useStaticQuery(graphql`
    query RegisterForm {
      strapiPageRegister {
        registerForm {
          heading {
            preHeading
            primaryHeading
          }
          firstNameField {
            label
            placeholder
            requiredError
          }
          emailField {
            label
            placeholder
            requiredError
            invalidFormatError
          }
          registerButton {
            color
            text
            type
          }
          passwordField {
            invalidFormatError
            label
            placeholder
            requiredError
          }
          goToLoginText {
            primaryText
            link
          }
        }
        preferredCurrency {
          label
          placeholder
          requiredError
          currencyList {
            value
            label
          }
        }
      }
    }
  `)

  const [nameToShow, setNameToShow] = useState(name)

  useEffect(() => {
    const niceName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    setNameToShow(niceName)
    setFieldValue("firstName", niceName)
  }, [name, setFieldValue])

  const {
    heading,
    firstNameField,
    emailField,
    registerButton,
    passwordField,
    goToLoginText,
  } = strapiPageRegister.registerForm

  const {
    label: preferredCurrencyLabel,
    placeholder,
    currencyList,
  } = strapiPageRegister.preferredCurrency

  const handleNameChange = ({ target }) => {
    const niceName =
      target.value.charAt(0).toUpperCase() + target.value.slice(1).toLowerCase()
    setFieldValue("firstName", niceName)
    setNameToShow(niceName)
  }

  return (
    <Form autoComplete="off">
      <Rows gap="lg">
        <Heading level={3} preheading={`${heading.preHeading} ${nameToShow}!`}>
          {status || heading.primaryHeading}
        </Heading>
        <Copy>
          <FormField
            name="firstName"
            required
            type="text"
            placeholder={firstNameField.placeholder}
            label={firstNameField.label}
            touched={touched.firstName}
            error={errors.firstName}
            onChange={handleNameChange}
          />
          <FormField
            name="email"
            required
            type="text"
            placeholder={emailField.placeholder}
            label={emailField.label}
            touched={touched.email}
            error={errors.email}
          />
          <Columns count="2" colgap className="register__responsive">
            <FormField
              name="password"
              required
              type="password"
              placeholder={passwordField.placeholder}
              label={passwordField.label}
              touched={touched.password}
              error={errors.password}
            />

            <FormField
              name="currency"
              required
              selectOptions={currencyList.map(currency => ({
                text: currency.label,
                value: currency.value,
              }))}
              fieldAs="select"
              label={preferredCurrencyLabel}
              touched={touched.currency}
              error={errors.currency}
            />
          </Columns>
        </Copy>
        <Footer>
          <CtaGroup>
            <Button
              formButton
              color={registerButton.color}
              styletype={registerButton.type}
            >
              {agency ? "Register" : registerButton.text}
            </Button>
            <Link
              blank
              nofollow
              className="ml-sm link--grey"
              to={goToLoginText.link}
            >
              {goToLoginText.primaryText}
            </Link>
          </CtaGroup>
        </Footer>
      </Rows>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    return {
      firstName: props.name,
      email: "",
      password: "",
      currency: props.currency,
    }
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]*$/, "Please enter valid single first name")
      .max(40)
      .required("Name is Required"),
    currency: Yup.string().required("Please select a currency"),
    email: Yup.string()
      .email("Not valid email format")
      .required("An email is required"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
      ),
  }),
  handleSubmit(values, { resetForm, setErrors, setStatus }) {
    setStatus("Creating Account...")

    fetch("https://api.lunio.ai/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: values.firstName,
        email: values.email,
        password: values.password,
        country_code: "US",
        currency_code: values.currency,
      }),
    })
      .then(response => {
        if (response.status !== 404) {
          return response.json()
        }
        throw new Error()
      })
      .then(res => {
        if (res.success) {
          resetForm()
          setStatus("Success! Redirecting...")
          if (typeof window !== "undefined") {
            window.location.href = `https://dashboard.lunio.ai/login?bearer_token=${res.login_token}`
          }
        } else {
          setErrors(res.errors)
          setStatus("Check Fields for an Error")
        }
      })
      .catch(() => {
        setStatus(
          "Something unexpected has occurred, please try again or contact us if this persists.",
        )
      })
  },
})(RegisterForm)
