import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function FormField({
  name,
  placeholder,
  type = null,
  touched,
  error,
  label,
  required = false,
  fieldAs = 'input',
  selectOptions,
  ...attribs
}) {
  const formClasses = classNames(
    'form__field',
    selectOptions && 'form__field--select',
  );
  const inputClasses = classNames(
    'form__field__input',
    selectOptions && 'form__field__input--select',
    touched && 'form__field__input--touched',
  );

  return (
    <div className={formClasses}>
      {touched && error ? (
        <label className="form__field__label form__field__label--error">
          {error}
        </label>
      ) : (
        <label className="form__field__label">
          {label}
          {required && <span className="form__field__astrix">*</span>}
        </label>
      )}

      <Field
        className={inputClasses}
        name={name}
        placeholder={placeholder}
        type={type}
        as={fieldAs}
        {...attribs}
      >
        {selectOptions
          && selectOptions.map(({ value, text }, index) => (
            <option value={value} key={index}>
              {text}
            </option>
          ))}
      </Field>
      {selectOptions && (
        <div className="form__select-arrow">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}
    </div>
  );
}

export default FormField;
