import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { Content } from '../../components/grid';

function LogoColumn({ imageArray, currentImage }) {
  return (
    <Content paddingLeft="md" className="logo-row__column">
      {imageArray.map((item, index) => {
        const classList = classNames(
          'logo-row__image',
          index === currentImage && 'logo-row__image--active',
        );
        const img = getImage(item.localFile);
        return (
          <div className={classList} key={index}>
            <GatsbyImage image={img} alt={item.alternativeText} />
          </div>
        );
      })}
    </Content>
  );
}

export default LogoColumn;
