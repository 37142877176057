import React, { Component } from 'react';
import { Columns, Rows } from '../../components/grid';
import LogoColumn from './logo-column';

export default class LogoRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [0, 0, 0, 0, 0],
      intervals: [],
      counts: [
        props.logoImageData[0].logos.length,
        props.logoImageData[1].logos.length,
        props.logoImageData[2].logos.length,
        props.logoImageData[3].logos.length,
        props.logoImageData[4].logos.length,
      ],
    };
  }

  componentDidMount() {
    if (this.props.logoImageData[0].logos.length > 1) {
      this.columnOneIntervalFnc = setTimeout(
        () => this.initialImageChange(0),
        5000,
      );
    }
    if (this.props.logoImageData[1].logos.length > 1) {
      this.columnTwoIntervalFnc = setTimeout(
        () => this.initialImageChange(1),
        11000,
      );
    }
    if (this.props.logoImageData[2].logos.length > 1) {
      this.columnThreeIntervalFnc = setTimeout(
        () => this.initialImageChange(2),
        8000,
      );
    }
    if (this.props.logoImageData[3].logos.length > 1) {
      this.columnFourIntervalFnc = setTimeout(
        () => this.initialImageChange(3),
        9000,
      );
    }
    if (this.props.logoImageData[4].logos.length > 1) {
      this.columnFiveIntervalFnc = setTimeout(
        () => this.initialImageChange(4),
        4000,
      );
    }
  }

  componentWillUnmount() {
    if (this.props.logoImageData[0].logos.length > 1) {
      clearTimeout(this.columnOneIntervalFnc);
    }
    if (this.props.logoImageData[1].logos.length > 1) {
      clearTimeout(this.columnTwoIntervalFnc);
    }
    if (this.props.logoImageData[2].logos.length > 1) {
      clearTimeout(this.columnThreeIntervalFnc);
    }
    if (this.props.logoImageData[3].logos.length > 1) {
      clearTimeout(this.columnFourIntervalFnc);
    }
    if (this.props.logoImageData[4].logos.length > 1) {
      clearTimeout(this.columnFiveIntervalFnc);
    }
    this.state.intervals.forEach((item) => {
      clearInterval(item);
    });
  }

  initialImageChange = (index) => {
    this.incrementImage(index);
    const { intervals } = this.state;
    intervals.push(setInterval(() => this.incrementImage(index), 7000));
    this.setState({ ...this.state, intervals });
  };

  incrementImage = (index) => {
    let newValue = this.state.values[index] + 1;
    if (newValue >= this.state.counts[index]) {
      newValue = 0;
    }
    const values = [...this.state.values];
    values[index] = newValue;

    this.setState({
      ...this.state,
      values,
    });
  };

  render() {
    return (
      <Rows>
        <Columns count="1" alignment="center" className="logo-row__columns">
          {this.props.logoImageData.map(({ logos }, index) => (
            <LogoColumn
              key={index}
              imageArray={logos}
              currentImage={this.state.values[index]}
            />
          ))}
        </Columns>
      </Rows>
    );
  }
}
