import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import queryString from "query-string"
import LogoRow from "../components-smart/logo-row"
import Section from "../components/containers/section"
import SectionContent from "../components/containers/section-content"
import Copy from "../components/content/copy"
import Heading from "../components/content/heading"
import Statbox from "../components/content/statbox"
import { Columns, Content, Rows } from "../components/grid"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import Card from "../components/containers/card"
import RegisterForm from "../components-smart/forms/register-form"
import Link from "../components/content/link"

function Register({ data, location }) {
  const [name, setName] = useState(":)")
  const [agency, setAgency] = useState(false)
  const { seo, textBlock, statistics, logos, footerCopyright, footerLinks } =
    data.strapiPageRegister
  const date = new Date()

  useEffect(() => {
    const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
      const { name: parsedName, a } = queryString.parse(location.search)

      setAgency(!!a)

      if (parsedName) {
        setName(parsedName)
      } else {
        navigate("/")
      }
    }
  }, [location.search])

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main hidenav>
      <Seo customData={seoObj} />
      <Section theme="dark" className="section--tall">
        <SectionContent width="wide" paddingBot="none" paddingTop="xs">
          <Rows>
            <Columns count="2" alignment="center">
              <Content paddingRight="lg" paddingLeft="none">
                <Card
                  padding="lg"
                  className="section section--white"
                  styleType="solid"
                >
                  <RegisterForm agency={agency} name={name} />
                </Card>
              </Content>
              <Rows gap="lg">
                <Content>
                  <StaticImage
                    className="register__logo"
                    alt="lunio logo"
                    src="../images/logo/logo-white.svg"
                  />
                </Content>
                <Content>
                  <Heading>{textBlock.heading}</Heading>
                  <Copy contentToParse={textBlock.text} />
                </Content>
                <Columns className="grid__columns--statistics" count="auto">
                  {statistics.map(
                    ({ statDesc, statText, statTitleText }, index) => (
                      <Statbox
                        key={`register-stat--${index}`}
                        color="midgard"
                        statText={statText}
                        titleText={statTitleText}
                        descText={statDesc}
                      />
                    ),
                  )}
                </Columns>
                <LogoRow logoImageData={logos} />
              </Rows>
            </Columns>
            <Columns count="1">
              <div className="flex">
                {footerLinks.map((item, index) => (
                  <p className="mr-md" key={index}>
                    <Link className="text--color-white" to={item.link}>
                      {item.primaryText}
                    </Link>
                  </p>
                ))}

                <p className="text--strong text--color-white">
                  © Lunio {date.getFullYear()}
                </p>
              </div>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query RegisterPageQuery {
    strapiPageRegister {
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      textBlock {
        heading
        text
      }
      statistics {
        diamondColor
        statDesc
        statText
        statTitleText
      }
      footerCopyright
      footerLinks {
        link
        primaryText
      }
      logos {
        logos {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alternativeText
        }
      }
    }
  }
`

export default Register
