import React from "react"
import { Content } from "../grid"

function Statbox({
  color,
  statText,
  titleText,
  descText,
  includeCitation,
  citationMark,
  citationText,
}) {
  const position = { left: "-32px" }

  return (
    <Content>
      <div className="flex flex--align-center relative">
        <p className="h2 text--secondary">{statText}</p>
      </div>
      <div>
        <p className="h4 mb-xxxs">
          {titleText}
          {includeCitation && (
            <sup className="text--color-grey">{` [${citationMark}]`}</sup>
          )}
        </p>
        <p>{descText}</p>
      </div>
      {includeCitation && citationText && (
        <div>
          <p className="text--xs text--color-grey">
            {`${citationMark}. ${citationText}`}
          </p>
        </div>
      )}
    </Content>
  )
}

export default Statbox
